<template>
  <div class="listScroll_container" id="listScroll">
    <div class="listScroll_content" ref="listScrollList">
      <div class="listScroll_main">
        <slot name="listScrollHeader"></slot>
        <div v-for="(item, index) in list" :key="item">
          <slot name="listItem" :cItems="item" :cIndex="index"></slot>
          <!-- slot-scope -->
        </div>
        <loadings v-show="isPullingUp"></loadings>
        <div class="listScroll_nonedata" v-if="list.length == 0">
          暂没有数据哟
        </div>
        <div class="listScroll_nomore" v-if="list.length > 0 && noMore">
          暂没有更多数据了
        </div>
        <slot name="logo"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import tool from "@/common/tool";
import BScroll from "better-scroll";
import loadings from "@/components/unit/Loadings";
export default {
  name: "listScroll",
  props: {
    url: {
      type: String,
      default: "",
    },
    select: {
      type: Object,
      default: () => {
        return {};
      },
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      search: {
        page: 1,
        pagesize: 20,
      },
      useConfig: {
        haveMore: true,
      },
      list: [],
      collect: {},
      showIcon: false,
      scroll: undefined,
      scrollTop: 0,
      noMore: false,
      isPullingUp: false,
      isPullingDown: false,
      bounce_time: 600,
    };
  },
  beforeUnmount() {
    document
      .getElementById("listScroll")
      .removeEventListener("touchmove", this.$tools.noScroll, {
        passive: false,
      });
    // this.$tools.removeTouch()
  },
  created() {
    this.$nextTick(() => {
      document
        .getElementById("listScroll")
        .addEventListener("touchmove", this.$tools.noScroll, {
          passive: false,
        });
    });
    // this.$tools.addTouch()
    this.search = { ...this.search, ...this.select };
    this.useConfig = {
      ...this.useConfig,
      ...this.config,
    };
    this.initData();
    this.initScroll();
  },
  methods: {
    initData() {
      this.getData();
    },
    async getData(isClear = false) {
      await this.questData(isClear);
      setTimeout(() => {
        this.initScroll();
      }, this.bounce_time);
    },
    searchData(search = {}) {
      this.search = { ...this.search, ...search };
      this.search.page = 1;
      this.getData(true);
      this.scroll.scrollTo(0, 0);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.listScrollList, {
            click: true,
            mouseWheel: true,
            preventDefault: false,
            probeType: 3,
            bounce_time: this.bounce_time,
            pullUpLoad: true,
            pullDownRefresh: {
              threshold: 100,
              stop: 56,
            },
            pullUpRefresh: {
              threshold: 100,
              stop: 56,
            },
          });
          // 下拉刷新
          this.scroll.on("pullingDown", this.pullingDownHandler);
          this.scroll.on("pullingUp", this.pullingUpHandler);
          this.scroll.on("scroll", (pos) => {
            tool.setStorage("scrollTop", pos.y || 0, sessionStorage);
          });
          const top = tool.getStorage("scrollTop", sessionStorage) || 0;
          this.scroll.scrollTo(0, top);
        } else {
          this.scroll.refresh();
        }
      });
    },
    async pullingDownHandler() {
      if (this.useConfig.haveMore) {
        this.search.page = 1;
        this.noMore = false;
        this.isPullingDown = true;
        await this.questData(true);
      }
      this.finishPullDown();
    },
    async finishPullDown() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullDown();
          resolve();
        }, 600);
        resolve();
      });
      setTimeout(() => {
        this.isPullingDown = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async pullingUpHandler() {
      if (this.useConfig.haveMore) {
        if (!this.noMore) {
          this.search.page += 1;
          this.isPullingUp = true;
          await this.questData();
        }
      }
      this.finishPullUp();
    },
    async finishPullUp() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullUp();
          resolve();
        }, 600);
        resolve();
      });

      setTimeout(() => {
        this.isPullingUp = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async questData(isClear = false) {
      try {
        let data = await this.loadData();
        if (data.length < this.search.pagesize) {
          this.noMore = true;
        }
        this.list = isClear ? data : this.list.concat(data);
        console.log(this.list);
      } catch (err) {}
    },
    async loadData() {
      return new Promise((resolve) => {
        let url = tool.getURL(this.url, this.search);
        this.$axios
          .get(url)
          .then((response) => {
            let list = response.data || [];
            this.$emit("receiveResponse", response);
            resolve(list);
          })
          .catch((err) => {
            console.log(err);
            resolve([]);
          });
      });
    },
  },
  components: {
    loadings,
  },
};
</script>
<style>
.listScroll_container {
  height: 100%;
  width: 100%;
  position: relative;
  /* background:#f1f1f1;; */
  display: flex;
  flex-direction: column;
}
.listScroll_main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.listScroll_search {
  flex: 3.25rem 0 0;
  padding: 0.5rem 0.75rem;
}
.listScroll_search_input {
  padding: 0.5rem 0;
  position: relative;
  font-size: 0.9325rem;
  height: 2rem;
  display: flex;
  align-items: center;
}
.listScroll_search_input_text {
  flex: 1 0 0;
  position: relative;
  font-size: 0.9325rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 2rem;
}
.listScroll_search_placeholder {
  flex: 5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e87f0;
}
.listScroll_search .el-input__icon {
  line-height: 1.4rem;
}
.listScroll_search input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 1.4rem !important;
  line-height: 1.4rem !important;
  border: none;
  position: relative;
}
.listScroll_tab_list {
  flex: 2.5rem 0 0;
  width: 100%;
  /* display: flex; */
  background: #fff;
  /* align-items: center; */
  font-size: 0.9325rem;
  padding: 0.065rem 0.5rem;
  border-bottom: 1px solid #ececec;
  position: relative;
}
.listScroll_tab_content_name,
.listScroll_tab_right_line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.listScroll_tab_right_line div {
  flex: 1 0 0;
}
.listScroll_tab_right_line div > span {
  display: inline-block;
  /* height:100%; */
  width: 100%;
  text-align: center;
  border-right: 0.1rem solid #ececec;
}

.listScroll_tab_right_line:last-child div > span {
  border: none;
}
.listScroll_tab {
  flex: 1 0 0;
  text-align: center;
}
.listScroll_tab_name {
  display: inline-block;
  line-height: 2.5rem;
  font-size: 0.9325rem;
  font-weight: 600;
  /* border-bottom:3px solid #ececec; */
}
.listScroll_tab_select .listScroll_tab_name {
  color: #9d0049;
  border-bottom: 1px solid #9d0049;
}
.listScroll_content {
  flex: 1 0 0;
  width: 100%;
  overflow: hidden;
}
.listScroll_nonedata {
  height: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.listScroll_nomore {
  height: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.listScroll_container .box:first-child {
  margin-top: 0 !important;
}
</style>